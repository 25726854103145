import React from "react";
import img from "../../assets/illustrations/illustration_problemes.png";
import "../../css/Problem.css";

export default function Problem() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder text-center mb-4">
          <h3>Problème</h3>
          <div className="subtitle">
            Les défis du contrôle d'accès dans les bâtiments
          </div>
        </div>
        <div className="card">
          <div className="row g-0">
            <div className="col-lg-8 col-md-7 d-flex align-items-center">
              <div className="card-body bg-primary text-white text-column">
                <p className="card-text">
                  Les gestionnaires de bâtiments font face à des
                  <span> défis importants </span>
                  lorsqu'il s'agit de mettre en place un
                  <span> contrôle d'accès sécurisé et fluide</span> pour les
                  résidents et les maintenanciers.
                </p>
                <p className="card-text">
                  Les systèmes de <span>contrôle d'accès traditionnels</span>,
                  tels que les badges d'accès, présentent souvent des
                  <span> limitations en termes de praticité et de coûts</span>.
                  Ils ne permettent pas une
                  <span> gestion en temps réel</span> des accès et créent des
                  <span>
                    problèmes de sécurité en cas de perte ou de vol
                  </span>{" "}
                  des badges.
                </p>
                <p className="card-text">
                  De plus, les solutions actuelles ne sont pas toujours adaptées
                  aux besoins modernes de flexibilité et de réactivité, ce qui
                  rend le
                  <span> contrôle d'accès</span> de plus en plus complexe à
                  gérer.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 d-flex justify-content-center align-items-center ">
              <img
                src={img}
                className="img-fluid rounded-start "
                alt="Défis du contrôle d'accès"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
