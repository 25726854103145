import React from "react";
import img from "../../assets/illustrations/Illustration_API.png";
import img_automatisation from "../../assets/icones/Icone_API.png";
import img_gain from "../../assets/icones/Icone_euros_.png";
import "../../css/Api.css"; /* Assure-toi d'importer le nouveau fichier CSS */

export default function Api() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder mb-4">
          <h2>API de Gestion des Accès</h2>
          <p className="subtitle">
            Notre API est conçue pour une intégration facile avec vos systèmes
            existants.
          </p>
        </div>
        <div className="card">
          <div className="row g-5 d-flex align-items-center">
            <div className="col-md-6">
              <div className="card-body bg-primary rounded">
                <p className="custom-text-color">
                  <strong>Intégrez notre API</strong> à votre système existant
                  pour <strong>automatiser</strong> la gestion des accès en
                  toute simplicité.
                </p>
                <p className="custom-text-color">
                  Cette intégration vous permet de{" "}
                  <strong>créer, modifier et supprimer des accès</strong> de
                  manière <strong>dynamique et automatisée</strong>.
                </p>
                <p className="custom-text-color">
                  Réduisez ainsi les tâches manuelles et{" "}
                  <strong>minimisez les risques d'erreur</strong>. Optimisez
                  votre gestion des accès avec une solution rapide et efficace.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img
                src={img}
                className="img-fluid"
                alt="Illustration de l'intégration de l'API de gestion des accès"
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-around mt-3">
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_automatisation}
              alt="Automatisation des accès avec API"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Automatisation des accès</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_gain}
              alt="Gain de temps et d'argent grâce à l'API"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Gain de temps et d'argent</p>
          </div>
        </div>
      </section>
    </div>
  );
}
